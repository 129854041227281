<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <b-alert show variant="white" class="text-center">
            <div class="f-500 text-primary" v-tr>
                The Header applies to the entire website|L'en-tête s'applique à tout le site
            </div>
        </b-alert>

        <sidebar-group title="Menu">
            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Position</span>
                    <btn-responsive-prop v-model="block" field="anchor"/>
                </template>
                <b-select v-model="block.mediaProxy.anchor" @change="applyWebsiteStyle">
                    <option value="top" v-tr>Top|En haut</option>
                    <option value="right" v-tr>Right|À droite</option>
                    <option value="bottom" v-tr>Bottom|En bas</option>
                    <option value="left" v-tr>Left|À gauche</option>
                </b-select>
            </b-form-group>

            <b-form-group label-cols="4">
                <template #label>
                    <span v-tr>Size|Taille</span>
                    <btn-responsive-prop v-model="block" field="size"/>
                </template>
                <b-select v-model.number="block.mediaProxy.size" @change="applyWebsiteStyle">
                    <option v-for="n in 40" :key="`size-${n}`" :value="(n + 1) * 5">{{(n + 1) * 5}}px</option>
                </b-select>
            </b-form-group>
        </sidebar-group>

        <properties-component-image title="Logo" :value="block.logo" v-bind="componentProps" edit-background no-binding category="logo"/>
        <properties-component-navigation-links @edit-navigation="$emit('edit-navigation')" title="Main Links|Liens principaux" :value="block.links" v-bind="componentProps" no-binding/>
        <properties-component-navigation-links @edit-navigation="$emit('edit-navigation')" title="Before Links|Liens avant" :value="block.leftLinks" v-bind="componentProps" no-binding/>
        <properties-component-navigation-links @edit-navigation="$emit('edit-navigation')" title="After Links|Liens après" :value="block.rightLinks" v-bind="componentProps" no-binding/>
    </properties-block>
</template>

<script>
import BtnResponsiveProp from "@/components/editor/properties/components/btn-responsive-prop.vue";
import PropertiesComponentButton from "@/components/editor/properties/components/properties-component-button.vue";
import PropertiesComponentImage from "@/components/editor/properties/components/properties-component-image.vue";
import PropertiesComponentNavigationLinks from "@/components/editor/properties/components/properties-component-navigation-links.vue";
import PropertiesComponentSocialLinks from "@/components/editor/properties/components/properties-component-social-links.vue";
import PropertiesComponentText from "@/components/editor/properties/components/properties-component-text.vue";
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";

// properties-block-menu #hot-reload-debug
export default {
    name: `properties-block-menu`,
    components: {
        BtnResponsiveProp,
        PropertiesComponentNavigationLinks,
        PropertiesComponentSocialLinks,
        PropertiesComponentImage,
        PropertiesComponentButton,
        PropertiesComponentText,
        PropertiesBlock},
    extends: PropertiesBlock,
    methods: {
        applyWebsiteStyle() {
            this.cmsWebsite.applyStyle();
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
